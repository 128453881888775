/* ============================================================ */
/* settings */
/* ============================================================ */

/* colors
----------------------------------------------------------- */
$white: #fff;
$black: #000;
$black-lighten: #333;

$gray: #aaa;
$light-gray: #eee;

$red: #A80813;
$blue: #0860A8;
$dark-blue: #134380;
$navy: #022948;
$sea-green: #00B5A6;
$green: #3A7434;
$orange: #F26549;
$gold: #9F891B;


$base-font-color: #333;
$base-border-color: #ccc;
$base-bg-gray: #F9F9F9;

$bg-warning: #fffedf;
$bg-danger: #fff4f4;

$txt-red: #d80000;
$txt-gray: #aaa;

/* width
----------------------------------------------------------- */
$base-content-width: 1024px;
$content-narrow: 960px;

/* font
----------------------------------------------------------- */

// base
$base-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, 'Segoe UI', 'Noto Sans JP', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;

// serif
$font-serif: 'Noto Serif JP', 'Hiragino Mincho ProN', 'Yu Mincho', 游明朝, 'HGS明朝E', serif;

// Roboto
$font-roboto: 'Roboto', sans-serif;
