/* works-gnav-menu
----------------------------------------------------------- */

@include media-pc {

  .works-gnav-menu {
    width: 100%;
    background: $blue;

    a {
      color: $white;
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      max-width: $base-content-width;
      width: 100%;
      margin: 0 auto;
    }

    &__top {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 16px;
      color: $white;
      line-height: 1.4;

      &::before {
        position: absolute;
        top: 0;
        right: calc(100% - 210px);
        z-index: 1;
        width: 1000%;
        height: 100%;
        background: $dark-blue;
        transform: skew(12deg);
        content: "";
        @include transition;
      }

      > span {
        display: block;
        z-index: 2;
        font-size: 3.2rem;
        text-align: center;

        &::after {
          display: block;
          margin-top: 6px;
          font-family: "Roboto", sans-serif;
          font-size: 1.6rem;
          font-weight: 700;
          content: attr(data-text);
        }
      }
    }

    &__lower {
      display: flex;
      align-items: center;
      width: 70%;
      padding: 32px;
    }

    &__list {
    }

    &__lower-body {
      &:last-child {
        margin-left: 116px;
      }
    }

    &__lower-title {
      margin-bottom: 12px;
      color: $white;
      font-size: 1.8rem;
      font-weight: 700;
    }

    &__item {
      position: relative;
      padding-left: 24px;

      &::before {
        @include centering(false, true);
        left: 0;
        width: 12px;
        height: 2px;
        background: rgba($white, 0.5);
        content: "";
      }

      &:nth-child(n + 2) {
        margin-top: 12px;
      }

      a {
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-sp {
  .works-gnav-menu {
    background: $base-bg-gray;

    &__top {
      padding: 16px;
      background: $blue;
      color: $white;
    }

    &__lower {
      display: flex;
    }

    &__lower-body {
      flex: auto;
      margin: 0 8px;
      padding: 16px 8px;
    }

    &__lower-title {
      font-weight: 700;
    }

    &__item {
      position: relative;
      padding-left: 18px;
      font-size: 1.3rem;

      a {
        display: block;
      }

      &:not(:first-child) {
        margin-top: 16px;
      }

      &::before {
        @include centering(false, true);
        left: 0;
        width: 10px;
        height: 2px;
        background: $blue;
        content: "";
      }
    }
  }
}

/* works-shijiraku-hero
----------------------------------------------------------- */
.works-shijiraku-hero {
  overflow: hidden;
  background: #fafbfc;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: $base-content-width;
    margin: 0 auto;
    width: 100%;
    height: 600px;

    &::before {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: $dark-blue;
      content: "";
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: 50%;
    height: 600px;
    padding-left: 16px;
    background: $dark-blue;
    color: $white;

    > p {
      max-width: 100%;
    }

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      right: -24%;
      width: 100%;
      height: 100%;
      background: $dark-blue;
      transform: skew(12deg);
      content: "";
    }

    &::after {
      position: absolute;
      right: -60px;
      bottom: 32px;
      width: 698px;
      height: 196px;
      background: url(../../common/img/works/truck/bg_shijiraku_main.svg) no-repeat;
      background-size: contain;
      content: "";
    }
  }

  &__title {
    max-width: 410px;
    margin-top: -40px;
    text-align: center;
  }

  &__btn {
    margin: 0 auto;
    z-index: 100;

    > .c-btn {
      background: $sea-green;

      &:hover {
        background: $white;
        color: $sea-green;
        &::after {
          background: url(../../common/img/common/ico_arrow_big_sea-green.svg) no-repeat;
          content: "";
        }

      }

      &.-lightblue {
        background: #00B5A6;

        &:hover {
          background: $blue;
          color: $white;
          &::after {
            background: url(../../common/img/common/ico_arrow_big.svg) no-repeat;
            content: "";
          }
        }
      }
    }
  }

  &__intro-text {
    margin: 12px 0 16px;
    font-size: 2.8rem;
  }

  &__image {
    z-index: 1;

    img {
      max-width: inherit;
    }
  }

  &.-bus {
    .works-shijiraku-hero__inner {
      &::before {
        background: $green;
      }
    }

    .works-shijiraku-hero__text {
      background: $green;

      &::before {
        background: $green;
      }

      &::after {
        background: url(../../common/img/works/bus/bg_shijiraku_bus_main.svg) no-repeat;
        background-size: contain;
      }
    }
  }

  @media (min-width: 1080px) {
    &__text {
      margin-left: -20px;
    }
  }

  @include media-pc-s {
    &__text {
      margin-left: 0;
    }

    &__image {
      img {
        max-width: 100%;
      }
    }
  }

  @include media-sp {
    &__inner {
      flex-direction: column;
      height: auto;
    }

    &__text {
      width: 100%;
      height: auto;
      padding: 48px 16px 80px;

      &::before {
        content: none;
      }

      &::after {
        width: 400px;
        height: 113px;
        top: 18px;
        left: -40px;
      }
    }

    &__title {
      width: 80%;
      margin: 0 auto;
    }

    &__intro-text {
      font-size: 2rem;
    }

    &__image {
      margin-top: -60px;
    }
  }
}

/* works-remotework
----------------------------------------------------------- */
.works-remotework {
  overflow: hidden;
  background: #fafbfc;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: $base-content-width;
    margin: 0 auto;
    width: 100%;
    height: 600px;

    &::before {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: #0e5a89;
      content: "";
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: 50%;
    height: 600px;
    padding-left: 16px;
    background: #0e5a89;
    color: $white;

    > p {
      max-width: 410px;
    }

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      right: -24%;
      width: 100%;
      height: 100%;
      background: #0e5a89;
      transform: skew(12deg);
      content: "";
    }

    &::after {
      position: absolute;
      right: -60px;
      bottom: 32px;
      width: 698px;
      height: 196px;
      background: url(../../common/img/works/truck/bg_shijiraku_main.svg) no-repeat;
      background-size: contain;
      content: "";
    }
  }

  &__title {
    max-width: 60%;
    margin-top: -40px;
    text-align: center;
    p {
      margin-bottom: 6px;
    }

    h1 > img {
      max-width: 280px;
      width: auto;
      margin: auto;
    }
  }

  &__intro-text {
    margin: 12px 0 16px;
    font-size: 3.4rem;
  }

  &__image {
    z-index: 1;

    img {
      max-width: inherit;
    }
  }

  @media (min-width: 1080px) {
    &__text {
      margin-left: -20px;
    }
    &__title {
      max-width: 280px;
    }
    &__intro-text {
      font-size: 4rem;
    }
  }

  @include media-pc-s {
    &__text {
      margin-left: 0;
    }
    &__intro-text {
      font-size: 3rem;
    }

    &__image {
      img {
        max-width: 100%;
      }
    }
  }

  @include media-sp {
    &__inner {
      flex-direction: column;
      height: auto;
    }

    &__text {
      width: 100%;
      height: auto;
      padding: 48px 16px 80px;

      &::before {
        content: none;
      }

      &::after {
        width: 400px;
        height: 113px;
        top: 18px;
        left: -40px;
      }
    }

    &__title {
      margin: 0 auto;
      p {
        font-size: 1.4rem;
        margin-bottom: 4px;
      }
      h1 > img {
        max-width: 100%;
        width: auto;
        margin: auto;
      }
    }

    &__intro-text {
      font-size: 2.8rem;
      text-align: center;
    }

    &__image {
      margin-top: -60px;
    }
  }
}

/* slider
----------------------------------------------------------- */
.slider {
  position: relative;
  display: block;
}
.slider img {
  height: auto;
  width: 100%;
}
/*slick setting*/
.slick-prev:before,
.slick-next:before {
  color: #000;
}

.slider-arrow {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  height: 36px;
  margin-top: -18px;
  line-height: 36px;
  font-size: 28px;
  cursor: pointer;
  z-index: 10;
}

.slider-prev {
  margin-left: 10px;
  left: 0;
  width: 20px;
}

.slider-next {
  margin-right: 10px;
  right: 0;
  width: 20px;
}

.slide-dots {
  position: absolute;
  display: block;
  padding: 0;
  list-style: none;
  max-width: 964px;
  width: 96%;
  bottom: 50px;
  padding-right: 16px;
  margin: 0 auto;
  right: 0;
  left: 0;
  text-align: right;
  li {
    display: inline-block;
    margin: 0 7px;
    button {
      position: relative;
      text-indent: -9999px;
      background: transparent;
      &:before {
        content: "●";
        color: #cccccc;
        font-size: 20px;
        text-indent: 0px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &.slick-active {
      button:before {
        content: "●";
        color: #086dab;
      }
    }
  }
}

@media (min-width: 1080px) {
  .slider-prev {
    margin-left: 36px;
    left: 0;
    width: 32px;
  }
  .slider-next {
    margin-right: 36px;
    right: 0;
    width: 32px;
  }
}

@include media-pc-s {
  .slider-prev {
    margin-left: 6px;
    width: 20px;
  }
  .slider-next {
    margin-right: 6px;
    width: 20px;
  }
}

@include media-sp {
  .slick-dotted.slick-slider {
    margin-bottom: 60px;
  }
  .slide-dots {
    bottom: -44px;
    text-align: center;
    margin-bottom: 20px;
    li {
      margin: 0 6px;
      button {
        &:before {
          font-size: 20px;
        }
      }
    }
  }

  .slider-prev {
    margin-left: 6px;
    width: 16px;
  }
  .slider-next {
    margin-right: 6px;
    width: 16px;
  }
}

/* works-explain
----------------------------------------------------------- */
.works-explain {
  &__title {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid $base-border-color;
    font-size: 2.8rem;
    line-height: 1.5;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    top: 40px;
    right: 20px;
    width: 24px;
    height: 24px;
    @include transition;

    &.-active {
      transform: rotate(45deg);
    }
  }

  &__number {
    margin-right: 32px;
    font-family: $font-roboto;
    font-style: italic;
    font-size: 4rem;

    &.-blue {
      color: $dark-blue;
    }

    &.-green {
      color: $green;
    }

    &.-gold {
      color: $gold;
    }
  }

  &__content {
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0;

    &.-reverse {
      flex-flow: row-reverse;
    }

    &.-top {
      padding-top: 60px;
    }

    &.-full {
      display: block;
    }
  }

  &__image {
    width: 51%;
    margin: 0 12px;
    text-align: center;
    &.-full {
      width: 95%;
      margin: 0 auto;
    }
  }

  &__text {
    width: 47%;
    &.-full {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__list {
    padding-left: 1.5em;

    > li {
      position: relative;
      line-height: 1.8;
      font-size: 1.8rem;

      &:not(:first-child) {
        margin-top: 1em;
      }

      &::before {
        position: absolute;
        top: 0.5em;
        left: -1.5em;
        width: 13px;
        height: 10px;
        content: "";
      }
    }

    &.-blue {
      > li {
        &::before {
          background: url(../../common/img/common/ico_check_blue.svg) no-repeat;
        }
      }
    }

    &.-green {
      > li {
        &::before {
          background: url(../../common/img/common/ico_check_green.svg) no-repeat;
        }
      }
    }

    &.-gold {
      > li {
        &::before {
          background: url(../../common/img/common/ico_check_gold.svg) no-repeat;
        }
      }
    }
  }

  @include media-sp {
    &__body {
      flex-direction: column;
      padding: 24px 0 40px;

      &.-reverse {
        flex-direction: column;
      }
    }

    &__title {
      padding: 12px 32px 12px 0;
      font-size: 1.8rem;
    }

    &__number {
      margin-right: 16px;
      font-size: 2.8rem;
    }

    &__icon {
      top: 24px;
      right: 0;
      width: 18px;
      height: 18px;
    }

    &__image {
      width: 100%;
      margin-bottom: 16px;
    }

    &__text {
      width: 100%;
      padding-left: 12px;
    }

    &__list {
      > li {
        font-size: 1.6rem;
      }
    }
  }
}

/* works-merit
----------------------------------------------------------- */
.works-merit {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -16px;

  &__item {
    width: 320px;
    margin: 0 16px 48px;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    margin-bottom: 32px;
    text-align: center;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 2.8rem;
    text-align: center;
  }

  @include media-sp {
    &__item {
      margin-bottom: 32px;
    }

    &__title {
      font-size: 2rem;
    }

    &__image {
      min-height: auto;
      margin-bottom: 24px;
    }
  }
}

/* works-voice
----------------------------------------------------------- */
.works-voice {
  background: $white;
  padding: 24px;
  width: 32%;
  border-radius: 4px;
  margin: 0 1%;

  &-heading {
    color: $white;
  }

  &-outer {
    display: flex;
  margin: 0 -1%  96px;
  }

  &__icon {
    width: 100px;
    height: auto;
    margin: 0 auto 24px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__text {
    position: relative;

    &-highlight {
      font-weight: bold;
      background: #fff48d;
      padding: 2px 8px;
      font-size: 1.4rem;
      display: inline-block;
    }
  }
  @include media-sp {
  width: 100%;
  margin: 0 0 24px;

  &-outer {
    flex-direction: column;
  margin: 0 0 48px;
  }
  }
}
/* truck-mail
----------------------------------------------------------- */
.truck-mail {
  background: #00b5a6;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
 > a {
  display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -4px;
    width: 100%;
    height: 100%;

    &-img {
      width: 30px;
      img {
        width: 100%;
        height: auto;
      }
    }

    span {
      font-size: 1rem;
      display: block;
      color: #fff;
      font-weight: bold;
    }
  }
}
