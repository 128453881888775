.c-anchor {
  @include media-pc {
    padding: 64px 24px;

    &__list {
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-item {
        padding: 0 16px;
        margin-bottom: 8px;
        position: relative;

        &:first-child::before,
        &::after {
          content: "";
          display: block;
          height: 1em;
          width: 1px;
          background: $base-border-color;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }

        &:first-child::before {
          left: 0;
        }
        &::after {
          right: 0;
        }

        > a {
          display: flex;
          align-items: center;
          transition: 0.3s;

          &::after {
            content: "";
            display: block;
            height: 8px;
            width: 8px;
            border-right: solid #333 1px;
            border-top: solid #333 1px;
            margin: 0 0 6px 12px;
            transform: rotate(135deg);
          }

          &:hover {
            color: #0860a8;
            &::after {
              border-color: #0860a8;
            }
          }
        }
      }
    }
  }

  @include media-sp {
    padding: 40px 16px;
    &__list {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 50%;
        padding: 0 28px 0 12px;
        margin-bottom: 16px;
        display: flex;
        position: relative;
        border-right: 1px solid $base-border-color;
        &:nth-child(odd) {
          border-left: 1px solid $base-border-color;
        }

        > a {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          height: 100%;
        }

        &::after {
          content: "";
          display: block;
          height: 8px;
          width: 8px;
          border-right: solid #333 1px;
          border-top: solid #333 1px;
          margin: 0 0 6px 12px;
          position: absolute;
          right: 12px;
          top: 45%;
          transform: translate(0, -45%) rotate(135deg);
        }
      }
    }
  }

  @include media-sp-xs {
    padding: 40px 10px;
  }
}
