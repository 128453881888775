.c-section {
  margin: 0 auto ;

  &.-narrow {
    max-width: 880px;
  }

  &.-small {
    margin-bottom: 40px !important;
  }

  &.-medium {
    margin-bottom: 56px !important;
  }

  &.-large {
    margin-bottom: 72px !important;
  }

  &.-xlarge {
    margin-bottom: 96px !important;
  }

  @include media-sp {
    &.-small {
      margin-bottom: 32px !important;
    }

    &.-medium {
      margin-bottom: 40px !important;
    }

    &.-large {
      margin-bottom: 48px !important;
    }

    &.-xlarge {
      margin-bottom: 64px !important;
    }
  }
}
