.c-sentence {
  
  &.-note {
    display: table;
    
    span {
      &:first-child {
        display: table-cell;
        padding-right: 1em;
      }
    }
  }
  
  &.-large {
    font-size: 1.8rem;
  }
  
  &.-small {
    font-size: 1.4rem;
  }
  
  &.-xsmall {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  &.-right {
    text-align: right;
  }
  
  @include media-sp {
    &.-large {
      font-size: 1.6rem;
    }
  }
}