/* c-form
----------------------------------------------------------- */
.c-form-block {
  max-width: 672px;
  margin: 0 auto;

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 36px;

    button {
      margin: 0 16px;
    }
  }

  &__privacy {
    overflow-y: auto;
    height: 300px;
    margin: 40px 0 34px;
    padding: 24px;
    background: $base-bg-gray;

    p {
      font-size: 1.4rem;
    }

    ul li {
      font-size: 1.4rem;
    }

    &.-white {
      background: $white;
    }
  }

  @include media-sp {

    &__btns {
      margin-top: 24px;

      button {
        width: 50%;
        margin: 0 8px;
      }
    }

    &__privacy {
      margin: 24px 0 16px;
      padding: 16px;
    }
  }
}

.c-form {

  + .c-form {
    margin-top: 36px;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 1.8rem;
    font-weight: 700;

    &--large {
      min-width: 260px;
    }
  }

  &__label {
    display: inline-block;
    min-width: 40px;
    margin-left: 14px;
    padding: 4px;
    border-radius: 2px;
    background: $red;
    color: $white;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1;
  }

  &__body {
    width: 100%;
    line-height: 1.8;

    p {
      margin-bottom: 8px;
    }

    // エラー表示
    &.-error {
      .c-input-text {
        background: $bg-danger;

        &:focus {
          background: $bg-warning;
        }
      }
    }
  }

  &__error-message {
    position: relative;
    margin-bottom: .5em;
    padding-left: 24px;
    color: $red;
    font-weight: 700;

    &::before {
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      background: url(../../common/img/common/ico_warning_circle.svg);
      content: "";
    }
  }

  &__btn-list {
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 50%;

      &:nth-child(n + 3) {
        margin-top: 12px;
      }
    }
  }

  @include media-sp {
    flex-direction: column;

    + .c-form {
      margin-top: 24px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 1.6rem;
    }

    &__btn-list {
      > li {
        width: 100%;
        margin-top: 12px;
      }
    }

    &__error-message {
      font-size: 1.6rem;
    }
  }
}



/* c-input-text
----------------------------------------------------------- */

.c-input-text {
  width: 100%;
  padding: 14px 20px;
  border: 1px solid $gray;
  font-size: 1.6rem;

  &:focus {
    background: $bg-warning;
  }

  &::placeholder {
    opacity: 1;
    color: $gray;
  }

  @include media-pc {
    &.-small {
      width: 250px;
    }
  }

  @include media-sp {
    width: 100%;
    padding: 14px;
  }
}

/* c-input-textarea
----------------------------------------------------------- */

.c-input-textarea {
  resize: vertical;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid $gray;
  font-size: 1.6rem;

  &:focus {
    background: $bg-warning;
  }
}

/* c-checkbox
----------------------------------------------------------- */

.c-checkbox {
  font-size: 1.6rem;

  &__input {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .c-checkbox__text::before {
      border-color: $blue;
    }

    &:checked + .c-checkbox__text::after {
      display: block;
      position: absolute;
      top: 1px;
      left: 5px;
      width: 6px;
      height: 10px;
      transform: rotate(40deg);
      border-bottom: 2px solid $blue;
      border-right: 2px solid $blue;
      content: "";
    }

    &:focus + .c-checkbox__text::before {
      outline: 1px dashed $blue;
    }
  }

  &__text {
    position: relative;
    padding-left: 23px;
    cursor: pointer;
    line-height: 1.4;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid $gray;
      border-radius: 2px;
      content: "";
    }
  }
}
