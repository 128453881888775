@media print{

  .aos-init {
    opacity: 1 !important;
  }

  .top-company-link {
    background-color: $blue !important;
  }
}body {
-webkit-print-color-adjust: exact;
}