.c-local-nav {
  
  &__list {
    + .c-local-nav__list {
      margin-top: 32px;
    }
  }
  
  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    border: 1px solid #ccc;
    background: #fff;
    color: #333;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.4;
    @include transition;
    
    &.-current {
      border: none;
      background: $light-gray;
      pointer-events: none;
      cursor: default;
    }
  }
  
  @include media-pc {

    &__btn {
      
      &:hover,
      &:focus {
        background: $base-bg-gray;
        color: $blue;
      }
    }
  }
  
  @include media-sp {
    &__btn {
      padding: 12px;
      font-size: 1.4rem;
    }
  }
}
