.c-heading-main {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;

  &.-large {
    font-size: 4rem;
  }

  &.-medium {
    font-size: 3.2rem;
  }

  &.-blue {
    color: $dark-blue;
  }

  &.-green {
    color: $green;
  }

  &.-gold {
    color: $gold;
  }

  &.-white {
    color: $white;

    .c-heading-main__subtext {
      color: $white;
    }
  }

  &__subtext {
    display: block;
    margin-top: 8px;
    color: $dark-blue;
    font-family: $font-roboto;
    font-size: 1.6rem;

    &.-blue {
      color: $blue;
    }

    &.-green {
      color: $green;
    }
  }

  @include media-sp {
    margin-bottom: 32px;

    &.-large {
      font-size: 2.6rem;
    }

    &.-medium {
      font-size: 2.2rem;
    }
  }
}

.c-heading {
  position: relative;
  margin-bottom: 1em;
  line-height: 1.4;

  &.-large {
    margin-bottom: 1.5em;
    font-size: 2.8rem;
  }

  &.-medium {
    font-size: 2.4rem;
  }

  &.-small {
    font-size: 1.8rem;
  }

  &.-xsmall {
    font-size: 1.6rem;
  }

  &.-xxsmall {
    font-size: 1.4rem;
  }

  &.-bold {
    font-weight: 700;
  }

  &.-center {
    text-align: center;
  }

  &.-sideline {
    padding: 8px 0 8px 16px;
    border-left: 4px solid $blue;
  }

  &.-underline {
    padding-bottom: 12px;
    border-bottom: 4px solid $light-gray;

    &::before {
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 230px;
      height: 4px;
      background: $blue;
      content: "";
    }

    &-white {
      padding-bottom: 12px;
      border-bottom: 4px solid $white;
      color: $white;
    }

    &-black {
      padding-bottom: 12px;
      border-bottom: 3px solid $blue;
      color: $black;
    }
  }

  @include media-sp {
    &.-large {
      margin-bottom: 1em;
      font-size: 2.2rem;
    }

    &.-medium {
      font-size: 1.8rem;
    }

    &.-small {
      font-size: 1.6rem;
    }

    &.-sideline {
      padding: 4px 0 4px 12px;
    }
  }
}
