
.qa-box {

  &__title {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid $base-border-color;
    font-size: 2.8rem;
    line-height: 1.5;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    top: 40px;
    right: 20px;
    width: 24px;
    height: 24px;
    @include transition;

    &.-active {
      transform: rotate(45deg);
    }
  }

  &__number {
    margin-right: 32px;
    color: $dark-blue;
    font-size: 4rem;
    font-weight: 700;
  }

  &__content {
    display: none;
    padding: 40px;
  }

  @include media-sp {

    &__title {
      padding: 12px 32px 12px 0;
      font-size: 1.8rem;
    }

    &__number {
      margin-right: 16px;
      font-size: 2.8rem;
    }

    &__icon {
      top: 24px;
      right: 0;
      width: 18px;
      height: 18px;
    }
  }
}