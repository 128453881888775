.l-footer {
  &__pagetop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 60px;
    padding: 12px;
    background: #0081cc;
    color: #fff;

    span {
      text-indent: -9999px;
    }

    &::before {
      @include centering(true, true);
      width: 24px;
      height: 12px;
      background: url(../../common/img/common/ico_arrow_top_wthite.svg) no-repeat;
      content: "";
    }

    @include media-pc {
      &:hover,
      &:focus {
        background: $dark-blue;
      }
    }
  }

  &__info {
    background: $navy;
    padding: 0 16px;
  }

  @include media-sp {
    &__pagetop {
      height: 48px;
    }
  }
}

.l-footer-certified {
  display: flex;
  justify-content: space-between;
  max-width: $base-content-width;
  margin: 0 auto;
  padding: 24px 16px;

  &__item {
    display: flex;
  }

  &__link {
    display: flex;
  }

  &__text {
    margin-left: 16px;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  @include media-sp {
    flex-direction: column;

    &__item {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}

.l-footer-bottom-info {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: $base-content-width;
  margin: 0 auto 24px;
  font-size: 1rem;
  text-align: center;

  &__copyright {
    margin-bottom: 0;
  }

  &__examination {
    position: absolute;
    right: 0;
  }

  @include media-sp {
    flex-direction: column;

    &__examination {
      position: relative;
      margin-top: 8px;
    }
  }
}

.l-footer-info {
  display: flex;
  flex-wrap: wrap;
  max-width: $base-content-width;
  margin: 0 auto;
  padding: 72px 0;
  color: $white;
  font-size: 1.4rem;

  &__profile {
    margin-right: 80px;
  }

  &__logo {
    display: block;
    margin-bottom: 20px;
  }

  &__nav {
    &.-col-2 {
      display: flex;
    }
  }

  &__nav-list {
    margin-right: 40px;
  }

  &__nav-link {
    display: block;
    margin-bottom: 8px;
    color: rgba($white, .6);
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }

    &.-disabled {
      &:hover {
        text-decoration: none;
      }
    }

    &.-lv1,
    &.-lv2 {
      color: $white;
      font-weight: 700;
    }

    &.-lv1 {
      margin-bottom: 10px;
      font-size: 1.6rem;
    }
  }

  &__contact {
    width: 100%;
    margin-top: 28px;
    padding-top: 28px;
    border-top: 1px solid #aaa;
    p {
      margin-bottom: 4px;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  @include media-pc {
    &__nav-list {

      + .l-footer-info__nav-list {
        margin-top: 34px;
      }
    }
  }

  @include media-sp {
    padding: 32px 0;

    &__profile {
      margin: 0 auto 24px;
      
      a {
        color: $white;
      }
    }

    &__nav {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &.-col-2 {
        margin-top: 24px;

       .l-footer-info__nav-list:last-child {
          margin-top: 40px;
        }
      }
    }

    &__nav-list {
      width: 48%;
      margin-right: 0;


    }

    &__nav-link {
      margin-bottom: 12px;
      font-size: 1.3rem;

      &.-lv1 {
        margin-bottom: 16px;
        font-size: 1.5rem;
      }
    }
  }
}


/* 先行公開ページ用のフッター調整
----------------------------------------------------------- */

/*.l-footer-info {
  align-items: center;
  padding-top: 48px;


  &__contact {
    width: inherit;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}*/
