.l-breadcrumb {
  margin: 20px auto 0;
  padding: 0 24px;

  &__inner {
    max-width: 64pc;
    margin: 0 auto;
  }
}

@include media-sp {
  .l-breadcrumb {
    padding: 0 1pc;
  }
}

@include media-sp-xs {
  .l-breadcrumb {
    padding: 0 10px;
  }
}