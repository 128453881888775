.c-basic-list {
  > li {
    display: table;

    &:not(:first-child) {
      margin-top: .75em;
    }

    span {
      &:first-child {
        display: table-cell;
        padding-right: 1em;
      }
    }
  }
}

.c-list {
  
  > li {
    position: relative;
    padding-left: 1.5em;
    line-height: 1.5;
    
    &:not(:first-child) {
      margin-top: .5em;
    }
    
    &::before {
      position: absolute;
      top: .6em;
      left: 0;
      width: 12px;
      height: 2px;
      background: $blue;
      content: "";
    }
  }
  
  &.-small {
    font-size: 1.4rem;
  }

  &.-gray {
    > li {
      &::before {
        background: $gray;
      }
    }
  }

  > .c-list__lower {
    &::before {
      content: none;
    }
  }
}

.c-description-list {
  
  &__item {
    display: flex;
    flex-wrap: wrap;
    padding: 22px 12px;
    border-top: 1px solid $base-border-color;
    &:last-child {
      border-bottom: 1px solid $base-border-color;
    }
    
    dt {
      width: 20%;
      padding-right: 16px;
    }

    dd {
      width: 80%;
    }
  }
  
  @include media-sp {
    &__item {
      padding: 12px 0;
      flex-direction: column;
      
      dt {
        margin-bottom: 6px;
        width: 100%;
      }
      
      dd {
        width: 100%;
      }
    }
  }
}

.c-ordered-list {

  list-style-type: decimal;
  padding-left: 1em;

  > li {
    padding-left: 1em;
    line-height: 2;

    &:not(:first-child) {
      margin-top: 1.2em;
    }
  }
}

.c-notes-list {

  > li {
    display: table;
    font-size: 1.4rem;
    line-height: 1.6;

    &:not(:first-child) {
      margin-top: .75em;
    }

    span {
      &:first-child {
        display: table-cell;
        padding-right: 12px;
      }
    }
  }

  &--large {
    > li {
      font-size: 1.6rem;
    }
  }
}

.c-link-list {
  li {
    
    &:not(:first-child) {
      margin-top: .75em;
    }
    
    a {
      display: block;
    }
  }
  
  @include media-pc {
    li {
      a {
        &:hover {
          color: $blue;
          text-decoration: underline;
        }
      }
    }
  }
}