.news-list {
  margin-bottom: 56px;

  @include media-sp {
    margin-bottom: 32px;
  }
}

.news-post {
  margin-bottom: 80px;

  &__header {
    margin-bottom: 48px;
  }

  &__title {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.4;
  }

  &__data {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  &__time {
    margin-right: 1em;
    font-size: 1.8rem;
  }

  &__body {
    a {
      color: $blue;
      text-decoration: underline;
    }
  }

  h2 {
    margin: 48px 0 24px;
    position: relative;
    padding-bottom: 12px;
    border-bottom: 4px solid $light-gray;
    font-size: 2.8rem;

    &::before {
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 230px;
      height: 4px;
      background: $blue;
      content: "";
    }
  }

  h3 {
    margin: 40px 0 20px;
    padding: 8px 0 8px 16px;
    border-left: 4px solid $blue;
    font-size: 2.4rem;
  }

  h4 {
    margin: 24px 0 16px;
    font-size: 1.8rem;
    font-weight: 700;
  }

  ul {
    margin-bottom: 1em;
    padding-left: 1em;
    list-style-type: disc;

    li {
      margin: .5em 0;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
    margin: 24px 0;

    th,
    td {
      padding: 14px 22px;
      border: 1px solid $base-border-color;
    }

    th {
      background: $blue;
      color: $white;
      font-weight: 400;
      text-align: left;
    }
  }

  @include media-sp {
    margin-bottom: 40px;

    &__header {
      margin-bottom: 32px;
    }

    &__title {
      font-size: 2.2rem;
    }

    &__time {
      font-size: 1.6rem;
    }

    table {
      margin: 16px 0;

      th,
      td {
        padding: 8px 12px;
      }
    }

    h2 {
      margin: 32px 0 16px;
      font-size: 2rem;

      &::before {
        width: 140px;
      }
    }

    h3 {
      margin: 24px 0 16px;
      padding: 4px 0 4px 12px;
      font-size: 1.8rem;
    }

    h4 {
      font-size: 1.6rem;
    }
  }
}

