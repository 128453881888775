.l-container {
  position: relative;
}

.l-main {
  overflow: hidden;
  padding-top: 82px;

  @include media-sp {
    padding-top: 50px;
  }
}
