/* l-gnav
----------------------------------------------------------- */
.l-gnav {

  @include media-pc {
    &__list {
      display: flex;
      height: 82px;
    }

    &__expantion-icon {
      display: none;
    }

    &__link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 22px;
      font-size: 1.8rem;
      text-align: center;

      &::after {
        display: block;
        font-family: $font-roboto;
        font-size: 1rem;
        font-weight: 700;
        content: attr(data-text);
      }

      &.-active,
      &:hover {
        background: $blue;
        color: $white;
      }
    }
  }

  @include media-pc-s {
    &__link {
      padding: 16px;
      font-size: 1.6rem;
    }
  }

  @include media-sp {
    width: 100%;
    border-bottom: 1px solid $base-border-color;

    &__list {

    }

    &__item {
      border-top: 1px solid $base-border-color;
    }

    &__link {
      display: block;
      position: relative;
      padding: 16px;

      &::after {
        @include centering(false, true);
        right: 16px;
        width: 18px;
        height: 6px;
        background: url(../../common/img/common/ico_arrow_black.svg);
        content: "";
      }

      &.-expantion {
        &::after {
          content: none;
        }
      }

    }

    &__expantion-icon {
      display: block;
      position: relative;
      float: right;
      top: 5px;
      width: 30px;
      height: 30px;
      margin-right: -12px;

      span {
        position: absolute;
        top: 5px;
        width: 50%;
        height: 1px;
        background-color: #333;
        transition: all .3s;

        &:last-child {
          top: 5px;
          transform: rotate(90deg);
        }
      }

      &.-active {
        span {
          &:first-child {
            display: none
          }

          &:last-child {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}