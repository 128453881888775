.c-table {
  table-layout: fixed;
  width: 100%;

  th,
  td {
    padding: 14px 22px;
    border: 1px solid $base-border-color;
    
    &.w-20 {
      width: 20%;
    }
    
    &.w-30 {
      width: 30%;
    }
    
    &.w-40 {
      width: 40%;
    }
  }

  th {
    background: $blue;
    color: $white;
    font-weight: 400;
    text-align: left;
  }
  
  @include media-sp {

    th,
    td {
      padding: 8px 12px;
    }
    
    &.-col-sp-1 {
      th,
      td {
        display: block;
        width: 100%;
        margin-top: -1px;
      }
      
      th {
        border: 1px solid $blue;
      }
    }
  }
}