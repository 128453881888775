.l-contact {
  text-align: center;
  padding: 64px 0;
  background: $base-bg-gray;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    padding: 0 16px;
  }

  &__title,
  &__title-02 {
    margin-bottom: 1em;
    font-size: 2.8rem;
    line-height: 1.5;
  }
  
  &__title-02 {
    padding-top: 36px;
    border-top: 1px solid $base-border-color;
  }

  &__btn {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    max-width: 530px;
    height: 88px;
    margin: 0 auto 42px;
    background: $sea-green;
    color: $white;
    font-size: 2.8rem;
    transition: all .3s;

    &::after {
      @include centering(false, true);
      right: 24px;
      width: 25px;
      height: 6px;
      background: url(../../common/img/common/ico_arrow_big.svg) no-repeat;
      content: "";
    }
  }
  
  &__tel-wrap {
    display: flex;
    justify-content: center;
  }
  
  &__tel {
    margin: 0;
    padding: 0 32px;
    font-size: 1.8rem;
    
    &:last-child {
      border-left: 1px solid $base-border-color;
    }
  }

  &__tel-number {
    padding: 0 6px;
    font-size: 2rem;
  }
  
  &__tel-title {
    font-size: 2.2rem;
  }

  @include media-pc {
    &__btn {
      &:hover,
      &:focus {
        background: $blue;
      }
    }
  }
  
  @include media-sp {
    padding: 40px 0;

    &__title,
    &__title-02 {
      font-size: 2rem;
    }
    
    &__title-02 {
      padding-top: 24px;
    }

    &__btn {
      height: 56px;
      margin-bottom: 32px;
      font-size: 2rem;
    }
    
    &__tel-wrap {
      flex-direction: column;
    }
    
    &__tel {
      padding: 0;
      
      &:last-child {
        margin-top: 20px;
        border: none;
      }
    }
    
    &__tel-title {
      font-size: 1.8rem;
    }
    
    &__tel-number {
      padding: 0 6px;
      font-size: 1.8rem;
    }
    
  }
}
