.c-box {
  padding: 24px;

  &.-white {
    background: $white;
  }

  &.-gray {
    background: $base-bg-gray;
  }

  &.-border {
    border: 1px solid $base-border-color;
  }

  &.-border-red {
    border: 1px solid $red;
  }

  &.-danger {
    background: $bg-danger;
  }

  &.-warning {
    background: $bg-warning;
  }

  p {
    line-height: 1.8;
  }

  @include media-sp {
    padding: 16px;
  }
}

.c-content-box {
  margin-bottom: 40px;
  padding: 40px;
  background: rgba($white, .5);

  &.-white {
    background: $white;
  }

  @include media-sp {
    padding: 16px;
  }
}

.c-link-box {
  padding: 6px 24px;
  background: $white;

  &__item {
    padding: 14px 0;

    &:not(:first-child) {
      border-top: 1px solid $base-border-color;
    }

    a {
      display: block;
      color: $blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      &:visited {
        color: $gray;
      }
    }
  }

  &__detail {
    margin-top: 16px;
    padding: 16px;
    background: $base-bg-gray;
    font-size: 1.4rem;
    line-height: 1.6;
  }

  @include media-sp {
    padding: 6px 16px;

    &__item {
      font-size: 1.4rem;
    }
  }
}

