* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  position: relative;
  font-size: 62.5%;

  &.-text-large {
    font-size: 72.5%;
  }

  &.-text-small {
    font-size: 58% !important;
  }
}

body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 1.6rem;
  line-height: 1.6;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and(-webkit-min-device-pixel-ratio: 2),(min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: $base-font-color;
  text-decoration: none;
  @include transition;
}

p {
  margin: 0 0 1em;
  line-height: 1.8;

  > span a,
  > a {
    color: $blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl,
dl dd {
  margin: 0;
}

table {
  border-collapse: collapse;
}

figure {
  margin: 0;
  padding: 0;
}

figcaption {
  line-height: 1.4;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: 500;
}

button, [type="button"] {
  appearance: none;
  margin: 0;
  border: none;
  cursor: pointer;
}

::selection {
  color: $white;
  background: $blue;
}

.row {
  margin: 0 auto;

  &--narrow {
    max-width: 880px;
  }
}

/* media-lg
----------------------------------------------------------- */

@include media-pc {

  body {
    font-size: 1.6rem;
  }

  a {
    cursor: pointer;

  }

  .sp\:view {
    display: none !important;
  }
}

/* media-sp
----------------------------------------------------------- */

@include media-sp {

  body {
    font-size: 1.4rem;
  }

  .pc\:view {
    display: none !important;
  }
}

/* media-sm
----------------------------------------------------------- */
