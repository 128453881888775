.c-label {
  min-width: 64px;
  padding: 3px 4px;
  font-size: 1.2rem;
  text-align: center;

  &.-orange {
    background: #FFEDDE;
  }

  &.-blue {
    /*background: #E0F1F3;*/
    background: #E2E9FF;
  }

  &.-green {
    background: #D5EFE3;
  }

  &.-yerrow {
    background: #f4ff54;
  }
}