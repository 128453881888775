/* l-header
----------------------------------------------------------- */
.l-header {
  position: fixed;
  width: 100%;
  transition: 0.3s;
  z-index: 20;
  padding: 0 16px;
  background: $white;

  &.-fixed {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $base-content-width;
    height: 82px;
    margin: 0 auto;
  }

  &__logo {
    a {
      display: block;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__contact-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 180px;
    height: 46px;
    background: $sea-green;
    color: $white;

    &::after {
      @include centering(false, true);
      right: 12px;
      width: 18px;
      height: 6px;
      background: url(../../common/img/common/ico_arrow.svg) no-repeat;
      content: "";
    }

    &:hover,
    &:focus {
      background: $blue;
    }
  }

  @include media-sp {
    z-index: 50;
    width: 100%;
    padding: 0;

    &__inner {
      height: 50px;
      padding: 6px 8px;
    }

    &__logo {
      img {
        height: 30px;
      }
    }

    &__nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 10;
      width: 100%;
      background: $white;
    }

    &__contact {
      padding: 24px;
    }

    &__contact-btn {
      margin: 0 auto;
    }
  }
}

/* l-header-text-size
----------------------------------------------------------- */
.l-header-text-size {
  margin-left: 12px;
  padding: 0 20px;
  border-left: 1px solid $txt-gray;
  color: $txt-gray;
  font-size: 1.2rem;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 4px;
  }

  button {
    display: inline-block;
    position: relative;
    padding: 4px;
    background: $white;
    color: $txt-gray;

    &.-current {
      &::after {
        @include centering(true, false);
        bottom: 1px;
        width: 10px;
        height: 1px;
        background: $txt-gray;
        content: "";
      }
    }
  }

  @include media-pc-s {
    padding: 0 12px;
  }

  @include media-sp {
    display: none;
  }
}

/* l-header-menu-btn
----------------------------------------------------------- */

.l-header-menu-btn {
  @include media-pc {
    display: none;
  }

  @include media-sp {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;

    span {
      display: inline-block;
      position: absolute;
      left: 14px;
      width: 22px;
      height: 2px;
      background: $dark-blue;
      transition: all .3s;

      &:first-child {
        top: 15px;
      }

      &:nth-child(2) {
        top: 23px;
      }

      &:last-child {
        top: 31px;
      }
    }

    &.-active {
      span {
        &:first-child {
          top: 23px;
          transform: rotate(-45deg);
        }

        &:nth-child(2) {
          top: 23px;
          transform: rotate(45deg);
        }

        &:last-child {
          opacity: 0;
        }
      }
    }
  }
}


/* l-header-sp-men
----------------------------------------------------------- */

.l-header-sp-menu {
  display: none;
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  padding: 16px 12px;
  border-top: 1px solid $base-border-color;
  background: $white;

  &__btns {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    width: 33%;
    height: 42px;
    font-size: 1.2rem;

    .material-icons {
      margin-right: 2px;
      font-size: 1.8rem;
    }
  }

  @include media-pc {
    display: none !important;
  }
}