.c-map {
  
  iframe {
    width: 100%;
    height: 200px;
  }
  
  &.-large {
    iframe {
      width: 100%;
      height: 280px;
    }
  }
  
  &.-small {
    iframe {
      width: 380px;
      height: 200px;
    }
  }
  
  @include media-sp {
    &.-small {
      iframe {
        width: 100%;
      }
    }
  }
  
}