.l-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 210px;
  background: $gray;
  color: $white;
  line-height: 1.4;

  &.-contact {
    background: url(../../common/img/title/bg_ttl_contact.jpg) no-repeat center/cover;
  }
  
  &.-news {
    background: url(../../common/img/title/bg_ttl_news.jpg) no-repeat center/cover;
  }
  
  &.-works {
    background: url(../../common/img/title/bg_ttl_works.jpg) no-repeat center/cover;
  }
  
  &.-company {
    background: url(../../common/img/title/bg_ttl_company.jpg) no-repeat center/cover;
  }
  
  &__text {
    text-align: center;
  }

  &__main {
    margin: 8px 0;
    font-size: 4rem;
    font-weight: 700;
  }
  
  &__sub {
    margin: 8px 0;
    font-family: $font-roboto;
    font-weight: 700;
  }
  
  @include media-sp {
    height: 100px;
    
    &__text {
      
    }
    
    &__main {
      margin: 6px 0;
      font-size: 2.2rem;
      line-height: 1.3;
    }
  }
}