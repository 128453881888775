/* top-hero
----------------------------------------------------------- */
@keyframes top-text-fadein {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes top-hero-scale {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes hover-in {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }

  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

@keyframes hover-out {
  0% {
    transform-origin: right top;
    transform: scale(1, 1);
  }

  100% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
}

.top-hero {
  overflow: hidden;
  position: relative;
  height: 750px;
  padding: 0 24px;
  pointer-events: none;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 1;
    width: 100%;
    height: 100%;
    margin-left: -24px;
    background: $base-bg-gray;
    transition: opacity .3s ease;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 750px;
    background: url(/common/img/top/img_hero_pc.jpg) no-repeat center/cover;
  }

  &__inner {
    display: flex;
    align-items: center;
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
  }

  &__text {
    position: relative;
    margin-top: -40px;
    color: $white;
  }

  &__en-copy {
    position: relative;
    margin-bottom: 28px;
    font-size: 6rem;
    line-height: 1.3;
    font-weight: 700;

    &:first-child {
      margin-bottom: 0;
    }
  }

  &__anm-text {
    opacity: 0;
  }

  &__main-copy {
    display: none;
    font-size: 3.2rem;
    font-weight: 700;
  }

  @include media-sp {
    height: 480px;
    padding: 50px 16px 0;
    
    &__text {
      margin-top: -64px;
    }

    &__image {
      height: 600px;
      background: url(/common/img/top/img_hero_sp.jpg) no-repeat left/cover;
      background-position: -60px -60px;
    }

    &__en-copy {
      margin-bottom: 14px;
      font-size: 2.8rem;
    }

    &__main-copy {
      font-size: 2rem;
    }
  }
}

.body-is-loaded .top-hero {

  &__loading {
    opacity: 0;
  }

  &__anm-bg {
    position: absolute;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transform: scale(0,1);
    transform-origin: left center;
    animation: hover-in .9s forwards alternate, hover-out .9s .9s forwards alternate;
    animation-delay: .5s;
    animation-timing-function: cubic-bezier(.785, .135, .15, .86);
    content: "";

    &::before {
    }

    &.-second {

      &::before {

      }
    }
  }

  &__en-copy {

    &.-second {
      .top-hero__anm-bg {
        animation-delay: 1s;
      }
      .top-hero__anm-text {
        animation-duration: 2s;
      }

    }
  }

  &__anm-text {
    opacity: 0;
    animation-name: top-text-fadein;
    animation-duration: 1s;

    &.-active {
      opacity: 1;
    }
  }

  &__main-copy {
    display: block;
  }
}


/* top-news
----------------------------------------------------------- */

.top-news-wrap {
  display: flex;
  max-width: $base-content-width;
  margin: -80px auto 0;
}

.top-news {
  display: flex;
  z-index: 40;
  width: 100%;
  min-height: 125px;
  box-shadow: 0px 3px 6px 0px rgba($black, 0.16);
  background: $white;

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 220px;
    background: $blue;

    &::after {
      position: absolute;
      top: 0;
      right: -20px;
      width: 80px;
      height: 100%;
      background: $blue;
      transform: skew(12deg);
      content: "";
    }
  }

  &__title {
    z-index: 1;
    color: $white;
    font-size: 3.2rem;
    text-align: center;
    line-height: 1;
  }

  &__en-title {
    display: block;
    margin-top: 8px;
    font-family: $font-roboto;
    font-size: 1.6rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 64px;
  }

  &__list {
    max-width: 590px;
    margin-right: 24px;
    padding: 16px 0;

    > li {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  &__list-link {
    display: block;
    position: relative;
    margin-right: 32px;
    padding: 6px 24px 6px 6px;
    font-size: 1.4rem;
    text-align: center;

    &::after {
      @include centering(false, true);
      right: 0;
      width: 18px;
      height: 6px;
      background: url(../../common/img/common/ico_arrow_black.svg);
      content: "";
      @include transition;
    }
  }

  @include media-pc {

    &__list-link {
      &:hover {
        color: $blue;
        &::after {
          margin-right: -5px;
        }
      }
    }
  }

  @include media-sp {
    flex-direction: column;
    margin: 0 16px;

    &__header {
      width: 100%;
      padding: 12px;

      &::after {
        content: none;
      }
    }

    &__title {
      font-size: 2.2rem;
    }

    &__en-title {
      display: inline-block;
      margin-left: 8px;
    }

    &__body {
      flex-direction: column;
      padding: 12px;
    }

    &__list {
      width: 100%;
      margin-right: 0;
      padding: 0;
    }

    &__list-link {
      margin: 24px 0 0 0;
    }
  }
}

/* top-news-item
----------------------------------------------------------- */

.top-news-item {

  &__link {
    display: flex;
    align-items: center;
  }

  &__time {
    margin-right: 12px;
  }

  &__text {
    margin-left: 14px;
    line-height: 1.5;
  }

  @include media-pc {
    &:hover {
      .top-news-item__text {
        color: $blue;
        text-decoration: underline;
      }
    }
  }

  @include media-sp {
    &__link {
      flex-wrap: wrap;
    }

    &__text {
      margin: 8px 0 0 0;
    }
  }
}

/* top-section
----------------------------------------------------------- */

.top-section {
  padding: 82px 16px;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  @include media-sp {
    padding: 56px 16px;
  }
}

/* top-works
----------------------------------------------------------- */
.top-works {

  + .top-works {
    margin-top: 72px;
  }

  &__title {
    margin-bottom: 32px;
    text-align: center;
    font-size: 3.2rem;
  }

  @include media-sp {
    + .top-works {
      margin-top: 32px;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 2.2rem;
    }

    &__list {
      > li {
        &:nth-child(n + 3) {
          margin-top: 12px;
        }
      }
    }
  }
}

/* top-works-link
----------------------------------------------------------- */

.top-works-link {
  &__image {
    overflow: hidden;
    margin-bottom: 16px;

    img {
      transition: all 0.3s ease-in-out;
    }
  }

  &__title {
    margin-bottom: 6px;
    font-size: 1.8rem;
    font-weight: 700;
  }

  @include media-pc {
    &:hover {
      color: $blue;

      img {
        transform: scale(1.1);
      }
    }
  }

  @include media-sp {
    &__image {
      img {
        width: 100%;
      }
    }

    &__title {
      font-size: 1.6rem;
    }
  }

}

/* top-company
----------------------------------------------------------- */
.top-company {
  background: url(../../common/img/top/bg_company.jpg) no-repeat center center/cover fixed;
  padding: 84px 16px 92px;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__list {
  }

  @include media-ie11 {
    background-attachment: scroll;
  }

  @include media-sp {
    padding: 48px 16px;
  }
}

/* top-company-link
----------------------------------------------------------- */
.top-company-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 212px;
  padding: 16px;
  border: 2px solid $white;
  color: $white;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    height: 116px;

    img {
      transition: all 0.3s ease-in-out;
    }
  }

  &__title {
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.5;
  }

  @include media-pc {
    &:hover,
    &:focus {
      background: rgba($white, .15);
      img {
        transform: scale(1.1);
      }
    }
  }

  @include media-sp {
    height: auto;

    &__image {
      height: auto;

      img {
        width: 80%;
      }
    }
  }
}

/* top-access
----------------------------------------------------------- */
.top-access {

  &__title {
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 700;
  }

  iframe {
    width: 100%;
    height: 280px;
  }

  &__info {
    margin-top: 14px;
    p {
      margin-bottom: 8px;
      line-height: 1.3;
    }
    small {
      font-size: 1.4rem;
    }
  }

  @include media-sp {

    + .top-access {
      margin-top: 24px;
    }

    iframe {
      height: 220px;
    }

    &__title {
      margin-bottom: 12px;
    }
  }
}


