.c-btn-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px -16px 0;

  > .c-btn {
    margin: 16px;
  }

  @include media-sp {
    margin: 32px -8px 0;

    > .c-btn {
      margin: 8px;
    }
  }
}

.c-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px 42px;
  color: $white;
  font-size: 2rem;
  line-height: 1.4;
  @include transition;

  &::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 24px;
    width: 25px;
    height: 6px;
    background: url(../../common/img/common/ico_arrow_big.svg) no-repeat;
    content: "";
  }

  &.-back {
    &::after {
      left: 24px;
      background: url(../../common/img/common/ico_arrow_big_left.svg) no-repeat;
    }
  }

  &.-blue {
    background: $blue;
  }

  &.-lightblue {
    background: $blue;

    &:hover {
      background: #00B5A6;
    }
  }

  &.-sea-green {
    background: $sea-green;
  }

  &.-gray {
    background: $gray;
  }

  &.-disabled {
    background: $gray;
    pointer-events: none;

    &:hover {
      background: $gray;
    }

    &::after {
      content: none;
    }
  }

  &.-gold {
    background: $gold;

    &:hover {
      background: #E6C021;
    }
  }

  &.-sea-green-white {
    background: $sea-green;
    color: $white;
    font-size: 2rem;
    font-weight: 500;

    &:hover {
      background: #fff;
      color:  $sea-green;

      &::after {
      background: url(../../common/img/common/ico_arrow_big_sea-green.svg) no-repeat;
      content: "";
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 24px;
      width: 25px;
      height: 6px;
      background: url(../../common/img/common/ico_arrow_big.svg) no-repeat;
      content: "";
    }

    > span {
      font-size: 1.8rem;
      line-height: 1.3;

      position: absolute;
      top: -30px;
      left: -25px;

      width: 80px;
      height: 80px;
      padding-top: 14px;

      -webkit-transition: all 0.3s;

      transition: all 0.3s;

      color: $black;
      border: 3px solid #fff;
      border-radius: 50%;
      background: #F7DA00;
    }

    @include media-pc {
      width: 700px;
      height: 80px;
    }

    @include media-sp {
      width: 100%;
      height: 100%;
      font-size: 1.5rem;

      &::after {
        right: 12px;
      }

      > span {
        font-size: 1.3rem;
        line-height: 1.3;

        position: absolute;
        top: -19px;
        left: -6px;

        width: 53px;
        height: 53px;
        padding-top: 7px;

        -webkit-transition: all 0.3s;

        transition: all 0.3s;

        color: $black;
        border: 3px solid #fff;
        border-radius: 50%;
        background: #F7DA00;
      }
    }
  }

  &.-max {
    width: 100%;
    min-width: inherit;
  }

  @include media-pc {
    min-width: 320px;
    min-height: 64px;

    &.-large {
      min-width: 440px;
    }

    &.-blue {
      &:hover,
      &:focus {
        background: $sea-green;
      }
    }

    &.-sea-green {
      &:hover,
      &:focus {
        background: $blue;
      }
    }

    &.-gray {
      &:hover,
      &:focus {
        background: #888888;
      }
    }
  }

  @include media-sp {
    font-size: 1.6rem;

    &::after {
      width: 20px;
      right: 10px;
      background-size: contain;
    }

    &.-sp-max {
      width: 100%;
    }

    &.-back {
      &::after {
        left: 10px;
      }
    }


  }
}