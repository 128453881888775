.c-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -12px -12px 0;

  /* ie不具合調整 */
  @include media-ie11 {
    margin: -12px -12px 0;
  }

  &__col {
    padding: 12px;

    &.-col-1 { width: calc(100% * 1 / 12); }
    &.-col-2 { width: calc(100% * 2 / 12); }
    &.-col-3 { width: calc(100% * 3 / 12); }
    &.-col-4 { width: calc(100% * 4 / 12); }
    &.-col-5 { width: calc(100% * 5 / 12); }
    &.-col-6 { width: calc(100% * 6 / 12); }
    &.-col-7 { width: calc(100% * 7 / 12); }
    &.-col-8 { width: calc(100% * 8 / 12); }
    &.-col-9 { width: calc(100% * 9 / 12); }
    &.-col-10 { width: calc(100% * 10 / 12); }
    &.-col-11 { width: calc(100% * 11 / 12); }
    &.-col-12 { width: calc(100% * 12 / 12); }
  }

  @include media-sp {
    margin: -8px -8px 0;

    &__col {
      padding: 8px;

      &.-col-sp-1 { width: calc(100% * 1 / 12); }
      &.-col-sp-2 { width: calc(100% * 2 / 12); }
      &.-col-sp-3 { width: calc(100% * 3 / 12); }
      &.-col-sp-4 { width: calc(100% * 4 / 12); }
      &.-col-sp-5 { width: calc(100% * 5 / 12); }
      &.-col-sp-6 { width: calc(100% * 6 / 12); }
      &.-col-sp-7 { width: calc(100% * 7 / 12); }
      &.-col-sp-8 { width: calc(100% * 8 / 12); }
      &.-col-sp-9 { width: calc(100% * 9 / 12); }
      &.-col-sp-10 { width: calc(100% * 10 / 12); }
      &.-col-sp-11 { width: calc(100% * 11 / 12); }
      &.-col-sp-12 { width: calc(100% * 12 / 12); }
    }
  }

  /* マージンなしのグリッド */
  &.-nogap {
    margin: 0;

    .c-grid__col {
      padding: 0;
    }
  }

  /* マージン狭めのグリッド */
  &.-narrow {
    margin: -4px -4px 0;

    .c-grid__col {
      padding: 4px;
    }
  }

  @include media-pc {
    /* マージン広めのグリッド */
    &.-wide {
      margin: -16px -16px 0;

      .c-grid__col {
        padding: 16px;
      }
    }
  }
  
  @include media-sp {
  }

}