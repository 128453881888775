.c-breadcrumb {
  display: flex;

  li {
    word-break: keep-all;
    overflow-wrap: normal;
    word-wrap: normal;

    &:last-child {
      word-break: break-all;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    a {
      color: #0860a8;
    }
  }

  li~li {
    margin-left: 3em;
    position: relative;

    &::before {
      content: ">";
      display: block;
      position: absolute;
      top: -.2em;
      left: -1.5em;
      font-size: 1.2em;
    }
  }
}

@include media-sp {
  .c-breadcrumb {
    font-size: .8em;

    li~li {
      margin-left: 2em;

      &::before {
        left: -1em;
      }
    }
  }
}
