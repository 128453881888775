.c-content-block {
  padding: 84px 24px;

  &__inner {
    max-width: 1024px;
    margin: 0 auto;

    &.-narrow {
      max-width: 672px;
    }
  }

  &.-gold {
    background: #fffdf7;
  }

  &.-green {
    background: #f3fffb;
  }

  &.-gray {
    background: #f9f9f9;
  }

  &.-voice {
    background: #e4f3fa;
  }

  &.-yellow {
    background: #FFEA93;
  }

  @include media-sp {
    padding: 40px 16px;
  }

  @include media-sp-xs {
    padding: 40px 10px;
  }
}
