.c-pagination {

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    border: 1px solid $base-border-color;
    &:not(:last-child) {
      border-right: none;
    }

    &.-current {
      border-top: 1px solid $blue;
      border-bottom: 1px solid $blue;

      span {
        display: block;
        padding: 16px 20px;
        background: $blue;
        color: $white;
      }
    }
  }

  &__link {
    display: block;
    padding: 16px 20px;
    color: $blue;

    &.-disabled {
      color: $gray;
      pointer-events: none;
    }
  }

  @include media-pc {
    &__link {
      &:hover,
      &:focus {
        background: $light-gray;
      }
    }
  }
  
  @include media-sp {
    &__item {
      &.-current {
        span {
          padding: 16px;
        }
      }
    }
    &__link {
      padding: 16px;
    }
  }
  
  @include media-sp-xs {
    
    &__item {
      font-size: 1.2rem;
      &:first-child,
      &:last-child {
      }
      &.-current {
        span {
          padding: 14px;
        }
      }
    }
    &__link {
      padding: 14px;
      font-size: 1.2rem;
    }
  }

}