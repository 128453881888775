.c-news {
  border-top: 1px solid $base-border-color;
  @include transition;
  
  &:last-child {
    border-bottom: 1px solid $base-border-color;
  }
  
  &__link {
    display: flex;
    align-items: center;
    padding: 22px 8px;
  }

  &__time {
    margin-right: 12px;
  }

  &__text {
    margin-left: 14px;
    line-height: 1.5;
  }

  @include media-pc {
    &:hover {
      background: $base-bg-gray;
      .c-news__text {
        color: $blue;
        text-decoration: none;
      }
    }
  }

  @include media-sp {
    &__link {
      flex-wrap: wrap;
      padding: 12px 6px;
    }

    &__text {
      width: 100%;
      margin: 8px 0 0 0;
    }
  }
}